import React, { useState } from "react";
import { navigate } from "gatsby";
import useForm from "react-hook-form";
import axios from "axios";
import Confetti from "react-dom-confetti";
import { Input, Check, Textarea } from ".";
import $ from "jquery";

const ContactFormDark = ({
  locales,
  select,
  facebookSelect,
  socialTypes,
  instagramSelect,
  messageRequired = true,
}) => {
  const { register, errors, handleSubmit, triggerValidation } = useForm({
    mode: "onChange",
  });
  const config = {
    angle: 90,
    spread: 100,
    startVelocity: 60,
    elementCount: 150,
    dragFriction: 0.1,
    duration: 2000,
    stagger: 0,
    width: "7px",
    height: "10px",
    colors: [
      "#E68F17",
      "#FAB005",
      "#FA5252",
      "#E64980",
      "#BE4BDB",
      "#0B7285",
      "#15AABF",
      "#EE1233",
      "#40C057",
    ],
  };

  const [isLoading, setLoading] = useState(false);
  const [showConfetti, triggerConfetti] = useState(false);

  const isBrowser = typeof window !== "undefined";
  const pageName = isBrowser ? document.title : null;

  const onSubmit = (data, e) => {
    if (data.bot_field) return false;
    delete data.bot_field;
    triggerConfetti(true);
    setLoading(true);
    setTimeout(() => {
      axios
        .post("https://adream-mailing.herokuapp.com/send-email-when", data)
        .then((res) => {
          setLoading(false);
          triggerConfetti(false);
          e.target.reset();
          console.log("Wysłane..");
          if (typeof window != "undefined") {
            if (
              window.location.href.indexOf(
                "audyt-kampanii-google-facebook-ads"
              ) > -1
            ) {
              navigate("/audyt-kampanii-google-fb-dziekujemy-za-kontakt");
            } else if (
              window.location.href.indexOf("audyt-kanalow-social-media") > -1
            ) {
              navigate("/audyt-kanalow-social-media-dziekujemy-za-kontakt");
            } else if (
              window.location.href.indexOf("kampanie-googleads-search") > -1
            ) {
              navigate("/kampanie-googleads-search-dziekujemy-za-kontakt");
            } else if (
              window.location.href.indexOf("kampanie-linkedin-ads") > -1
            ) {
              navigate("/kampanie-linkedin-ads-dziekujemy-za-kontakt");
            } else if (
              window.location.href.indexOf(
                "kampanie-produktowe-product-listing-ads"
              ) > -1
            ) {
              navigate("/kampanie-produktowe-pla-dziekujemy-za-kontakt");
            } else if (
              window.location.href.indexOf("kampanie-w-sieci-reklamowej-gdn") >
              -1
            ) {
              navigate(
                "/kampanie-w-sieci-reklamowej-gdn-dziekujemy-za-kontakt"
              );
            } else if (
              window.location.href.indexOf("kampanie-wideo-na-youtube") > -1
            ) {
              navigate("/kampanie-wideo-na-youtube-dziekujemy-za-kontakt");
            } else if (
              window.location.href.indexOf("marketing-dla-deweloperow") > -1
            ) {
              navigate("/marketing-dla-deweloperow-dziekujemy-za-kontakt");
            } else if (
              window.location.href.indexOf("marketing-dla-ecommerce") > -1
            ) {
              navigate("/marketing-dla-ecommerce-dziekujemy-za-kontakt");
            } else if (
              window.location.href.indexOf("marketing-dla-hoteli") > -1
            ) {
              navigate("/marketing-dla-hoteli-dziekujemy-za-kontakt");
            } else if (
              window.location.href.indexOf(
                "marketing-produktow-inwestycyjnych"
              ) > -1
            ) {
              navigate(
                "/marketing-produktow-inwestycyjnych-dziekujemy-za-kontakt"
              );
            } else if (
              window.location.href.indexOf("prowadzenie-konta-instagram") > -1
            ) {
              navigate("/prowadzenie-konta-instagram-dziekujemy-za-kontakt");
            } else if (
              window.location.href.indexOf(
                "analiza-strony-internetowej-przed-startem-kampanii-ppc"
              ) > -1
            ) {
              navigate(
                "/analiza-strony-internetowej-przed-startem-kampanii-ppc-dziekujemy-za-kontakt"
              );
            } else if (
              window.location.href.indexOf("prowadzenie-strony-na-facebooku") >
              -1
            ) {
              navigate(
                "/prowadzenie-strony-na-facebooku-dziekujemy-za-kontakt"
              );
            } else if (
              window.location.href.indexOf(
                "strategia-komunikacji-social-media"
              ) > -1
            ) {
              navigate(
                "/strategia-komunikacji-social-media-dziekujemy-za-kontakt"
              );
            } else if (
              window.location.href.indexOf("prowadzenie-strony-na-linkedin") >
              -1
            ) {
              navigate("/prowadzenie-strony-na-linkedin-dziekujemy-za-kontakt");
            } else if (
              window.location.href.indexOf("/kampanie-na-instagramie") > -1
            ) {
              navigate("/kampanie-na-instagramie-dziekujemy-za-kontakt");
            } else if (
              window.location.href.indexOf("/kampanie-facebook-ads") > -1
            ) {
              navigate("/kampanie-facebook-ads-dziekujemy-za-kontakt");
            } else if (
              window.location.href.indexOf("/testy-ab-google-optimize") > -1
            ) {
              navigate("/testy-ab-google-optimize-dziekujemy-za-kontakt");
            } else if (
              window.location.href.indexOf("/banery-statyczne-i-dynamiczne") >
              -1
            ) {
              navigate("/banery-statyczne-i-dynamiczne-dziekujemy-za-kontakt");
            } else if (
              window.location.href.indexOf("/wdrozenie-analityki") > -1
            ) {
              navigate("/wdrozenie-analityki-dziekujemy-za-kontakt");
            } else if (
              window.location.href.indexOf("/chatbot-w-komunikacji-marki") > -1
            ) {
              navigate("/chatbot-w-komunikacji-marki-dziekujemy-za-kontakt");
            } else {
              navigate("/dziekujemy-za-kontakt");
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(`Błąd wysyłania.. ${error}`);
        });
    }, 1500);
  };
  const selectColorChange = (e) => {
    e.preventDefault();
    let current = $("#audytType, #facebookPackage, #instagramPackage").val();
    if (current !== "Brak") {
      $("#audytType, #facebookPackage, #instagramPackage").css(
        "color",
        "black"
      );
    } else {
      $("#audytType, #facebookPackage, #instagramPackage").css(
        "color",
        "#c7c7c7"
      );
    }
  };
  return (
    <div className="wpcf7">
      {/* eslint-disable jsx-a11y/no-onchange */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="hidden">
          <Input
            type="hidden"
            name="pageTitle"
            value={pageName}
            register={register}
          />
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="input-wrapper">
              <Input
                type="text"
                name="name"
                label="Imię i Nazwisko"
                register={register}
                validation={{
                  required: locales.required,
                  message: locales.email_error,
                }}
                errors={errors}
                className="dark-input"
              />
            </div>
            {facebookSelect === true ? (
              <div className="input-wrapper">
                <div className="inp-group">
                  <div className="select_wrapper select_wrapper--dark-form">
                    <select
                      ref={register}
                      name="facebookPackage"
                      id="facebookPackage"
                      aria-label="Typ pakietu"
                      onChange={selectColorChange}
                    >
                      <option value="Brak" disabled selected hidden>
                        Wybierz pakiet
                      </option>
                      <option value="Pakiet Victor FB">Pakiet Victor FB</option>
                      <option value="Pakiet Winner FB">Pakiet Winner FB</option>
                      <option value="Pakiet Champion FB">
                        Pakiet Champion FB
                      </option>
                      <option value="Indywidualna propozycja FB">
                        Indywidualna propozycja FB
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {instagramSelect === true ? (
              <div className="input-wrapper">
                <div className="inp-group">
                  <div className="select_wrapper select_wrapper--dark-form">
                    <select
                      ref={register}
                      name="instagramPackage"
                      id="instagramPackage"
                      aria-label="Typ pakietu"
                      onChange={selectColorChange}
                    >
                      <option value="Brak" disabled selected hidden>
                        Wybierz pakiet
                      </option>
                      <option value="Pakiet Victor IG">Pakiet Victor IG</option>
                      <option value="Pakiet Winner IG">Pakiet Winner IG</option>
                      <option value="Pakiet Champion IG">
                        Pakiet Champion IG
                      </option>
                      <option value="Indywidualna propozycja IG">
                        Indywidualna propozycja IG
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="input-wrapper">
              <Input
                type="email"
                name="email"
                label="E-mail"
                register={register}
                validation={{
                  required: locales.required,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i,
                    message: locales.email_error,
                  },
                }}
                errors={errors}
                className="dark-input"
              />
            </div>
            <div className="input-wrapper">
              <Input
                type="tel"
                name="phone"
                label="Telefon"
                register={register}
                validation={{
                  required: locales.required,
                  message: locales.email_error,
                }}
                errors={errors}
                className="dark-input"
              />
            </div>
            <div className="input-wrapper">
              <Input
                type="text"
                name="website"
                label="Strona www"
                register={register}
                validation={{
                  message: locales.email_error,
                }}
                errors={errors}
                className="dark-input"
              />
            </div>
            {select === true ? (
              <div className="input-wrapper">
                <div className="inp-group">
                  <div className="select_wrapper select_wrapper--dark-form">
                    <select
                      register={register}
                      validation={{
                        required: locales.required,
                      }}
                      name="audytType"
                      id="audytType"
                      aria-label="Typ audytu"
                      onChange={selectColorChange}
                    >
                      <option value="Brak" disabled selected hidden>
                        Jaki audyt Cię interesuje?
                      </option>
                      <option value="Zaawansowany audyt">
                        Zaawansowany audyt kampanii
                      </option>
                      <option value="Darmowy audyt">
                        Bezpłatny audyt kampanii
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {socialTypes === true ? (
              <div className="checkbox-holder">
                <p className="mb-20">Zaznacz jakie kanały Cię interesują</p>
                <div className="row">
                  <div className="col-sm-4">
                    <Check
                      register={register}
                      name="typeFacebook"
                      errors={errors}
                      label={<p>Facebook</p>}
                    />
                  </div>
                  <div className="col-sm-4">
                    <Check
                      register={register}
                      name="typeInstagram"
                      errors={errors}
                      label={<p>Instagram</p>}
                    />
                  </div>
                  <div className="col-sm-4">
                    <Check
                      register={register}
                      name="typeLinkedin"
                      errors={errors}
                      label={<p>LinkedIn</p>}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6 textarea-col">
            <Textarea
              cols="40"
              name="message"
              label={locales.message}
              register={register}
              validation={
                !!messageRequired
                  ? {
                      required: locales.required,
                      message: locales.email_error,
                    }
                  : null
              }
              errors={errors}
              className="dark-input"
            />
          </div>
          <div className="col-md-6 acceptance-col">
            <Check
              register={register}
              name="term1"
              size="sm"
              validation={{ required: locales.required }}
              errors={errors}
              label={
                <p>
                  Wyrażam zgodę na przetwarzanie moich danych osobowych przez
                  When Sp. z o.o. z siedzibą w Krakowie, ul. Chopina 18/3a
                  (Administrator danych) w celu udzielenia mi dodatkowych
                  informacji handlowych z zakresu oferty Agencji. Podanie danych
                  jest dobrowolne i umożliwia uzyskanie informacji handlowej.
                </p>
              }
            />
            <Check
              register={register}
              name="term2"
              size="sm"
              validation={{ required: locales.required }}
              errors={errors}
              label={
                <p>
                  Wyrażam zgodę, na przetwarzanie moich danych osobowych przez
                  When Sp. z o.o. z siedzibą w Krakowie, ul. Chopina 18/3a
                  (Administrator danych), w celu marketingu bezpośredniego
                  dotyczącego własnych produktów i usług.
                </p>
              }
            />
            <Check
              register={register}
              name="term3"
              size="sm"
              errors={errors}
              label={
                <p>
                  Wyrażam zgodę na udostępnienie moich danych osobowych przez
                  When sp. z o. o., z siedzibą w Krakowie, ul. Chopina 18/3a,
                  KRS: 0000683438 jako administratora moich danych osobowych,
                  podmiotowi: Agencja Interaktywna ADream z siedzibą w Krakowie,
                  ul. Chopina 18/3a, KRS: 0000517132 (kolejnemu Administratorowi
                  danych), w celu udzielenia mi dodatkowych informacji
                  handlowych z zakresu oferty świadczonej przez Agencję
                  Interaktywną ADream. <br />
                  Podanie danych jest dobrowolne i umożliwia uzyskanie
                  informacji handlowej.*
                </p>
              }
            />
          </div>
          <div className="col-md-6">
            <button
              type="submit"
              aria-label="Wyślij"
              className="btn btn-box btn-submit"
              onClick={() => {
                triggerValidation();
              }}
            >
              {isLoading ? "wysyłanie..." : "wyślij"}
            </button>
            <Confetti active={showConfetti} config={config} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactFormDark;
