import React from "react";

const Check = ({
  type,
  name,
  label,
  size,
  register,
  errors,
  validation,
  color,
}) => {
  return (
    <div
      className={`check-group ${size ? size : ""} ${
        errors && errors[name] ? "has-error" : ""
      } ${color ? color : ""}`}
    >
      <input
        type="checkbox"
        aria-label="checkbox"
        id={name}
        name={name}
        ref={register(validation || {})}
      />
      <label htmlFor={name}>
        {validation && <em>*</em>}
        {label}
      </label>
      {errors && errors[name] && (
        <span role="alert" className="error">
          {errors[name].message}
        </span>
      )}
    </div>
  );
};

export default Check;
